@font-face {
  font-family: "icons";
  src: url('../../fonts/icons/icons.eot');
  src: url('../../fonts/icons/icons.eot?#iefix') format('eot'), url('../../fonts/icons/icons.woff') format('woff'), url('../../fonts/icons/icons.ttf') format('truetype'), url('../../fonts/icons/icons.svg#icons') format('svg'); }

@mixin icon-styles {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none; }

%icon {
  @include icon-styles; }

@function icon-char($filename) {
  $char: "";

  @if $filename == arrow-bottom {
    $char: "\E001"; }

  @if $filename == arrow-left {
    $char: "\E002"; }

  @if $filename == arrow-right {
    $char: "\E003"; }

  @if $filename == arrow-top {
    $char: "\E004"; }

  @if $filename == bulb {
    $char: "\E005"; }

  @if $filename == calendar {
    $char: "\E006"; }

  @if $filename == circle-cross {
    $char: "\E007"; }

  @if $filename == circle-exclamation-mark {
    $char: "\E008"; }

  @if $filename == circle-tick {
    $char: "\E009"; }

  @if $filename == facebook {
    $char: "\E00A"; }

  @if $filename == home {
    $char: "\E00B"; }

  @if $filename == information {
    $char: "\E00C"; }

  @if $filename == magnifier {
    $char: "\E00D"; }

  @if $filename == marker {
    $char: "\E00E"; }

  @if $filename == menu {
    $char: "\E00F"; }

  @if $filename == quote-left {
    $char: "\E010"; }

  @if $filename == quote-right {
    $char: "\E011"; }

  @if $filename == twitter {
    $char: "\E012"; }

  @return $char; }

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon; }
    @else {
      @include icon-styles; }
    content: icon-char($filename); } }

.icon-arrow-bottom {
  @include icon(arrow-bottom); }

.icon-arrow-left {
  @include icon(arrow-left); }

.icon-arrow-right {
  @include icon(arrow-right); }

.icon-arrow-top {
  @include icon(arrow-top); }

.icon-bulb {
  @include icon(bulb); }

.icon-calendar {
  @include icon(calendar); }

.icon-circle-cross {
  @include icon(circle-cross); }

.icon-circle-exclamation-mark {
  @include icon(circle-exclamation-mark); }

.icon-circle-tick {
  @include icon(circle-tick); }

.icon-facebook {
  @include icon(facebook); }

.icon-home {
  @include icon(home); }

.icon-information {
  @include icon(information); }

.icon-magnifier {
  @include icon(magnifier); }

.icon-marker {
  @include icon(marker); }

.icon-menu {
  @include icon(menu); }

.icon-quote-left {
  @include icon(quote-left); }

.icon-quote-right {
  @include icon(quote-right); }

.icon-twitter {
  @include icon(twitter); }
