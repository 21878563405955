// Bourbon (framework)
// ==================================================
@import "bourbon";

// Breakpoints settings
// ==================================================
@import "neat-helpers";

$big-desktop:   new-breakpoint(min-width 1600px 16);
$desktop:       new-breakpoint(min-width 1280px 16);
$small-desktop: new-breakpoint(min-width 960px max-width 1279px 12);
$tablet:        new-breakpoint(min-width 640px max-width 959px 8);
$mobile:        new-breakpoint(max-width 639px 4);

// Grid settings
// ==================================================
$column: 60px;
$gutter: 20px;
$grid-columns: 16;
$max-width: 100%;
$visual-grid: false;
$visual-grid-color: red;
$visual-grid-opacity: 0.25;
$visual-grid-index: front;

@import "neat";

// Container
// ==================================================
.container {
  @extend .clearfix;
  @include outer-container;
  position: relative; }
