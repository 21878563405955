// Mobile < 640px
// ==================================================
@include media($mobile) {

  $max-width: 300px;
  $header_height: 60px;

  @import "neat";

  body {
    padding-top: $header_height; }

  h1 {
    @include rem(font-size, 40px);
    @include rem(line-height, 40px);
    margin: 15px 0; }

  h2 {
    @include rem(font-size, 32px);
    @include rem(line-height, 32px);
    margin: 15px 0; }

  h3 {
    @include rem(font-size, 26px);
    @include rem(line-height, 26px);
    margin: 15px 0; }

  img {
    &.align-left,
    &.align-right {
      float: none;
      margin: 30px 0; } }

  blockquote {
    padding: 20px 0;
    &::after,
    &::before {
      @include rem(font-size, 36px);
      @include rem(line-height, 36px); }
    &::after {
      right: 0; }
    &::before {
      left: 0; } }

  table {
    thead {
      tr {
        th {
          padding: 5px; } } }
    tbody {
      tr {
        td {
          padding: 5px; } } } }

  iframe {
    height: auto;
    width: 100%; }

  .form-item {
    label {
      display: block;
      margin: 0 0 5px 0;
      text-align: left;
      width: auto; }
    .description {
      margin-left: 0; } }

  .captcha {
    margin-left: 0; }

  .form-submit {
    margin-left: 0; }

  .form-actions {
    padding-left: 0; }

  .invisible-on-desktop {
    display: block !important; }

  .container {
    @include outer-container;
    max-width: 300px; }

  .header {
    border-bottom: 0;
    .container {
      min-height: $header_height; } }

  .header-logo {
    a {
      line-height: $header_height; }
    img {
      max-height: 40px; } }

  .header-toggle-left-panel,
  .header-toggle-right-panel {
    @include span-columns(1);
    display: block; }

  .header-logo {
    @include span-columns(2); }

  .header-toggle-right-panel {
    @include omega;
    text-align: right; }

  .block-header-main-menu,
  .block-header-search,
  .block-header-accessibility {
    display: none; }

  .content {
    html.front & {
      background-size: cover; } }

  .block-highlighted-home-slideshows {
    .block-content {
      .slideshow-image {
        .field-content {
          height: 420px; }
        img {
          height: 100%;
          max-width: none;
          width: auto; } }
      .slideshow-informations {
        padding: 0 10px;
        strong {
          @include rem(font-size, 36px);
          @include rem(line-height, 50px); } } } }

  .block-highlighted-home-news {
    .block-content {
      .owl-item {
        min-height: 0;
        padding: 0 10px; }
      .views-field-field-image {
        float: none;
        height: 220px;
        overflow: hidden;
        width: 100%;
        img {
          height: auto;
          max-width: none;
          width: 100%; } }
      .views-field-nothing {
        float: none;
        width: 100%; } } }

  .block-content-home-services,
  .block-content-home-agenda,
  .block-content-home-idea,
  .block-content-home-map {
    margin-bottom: 30px;
    margin-top: 30px; }

  .block-content-home-city {
    min-height: 0;
    &::before {
      @media (min-width: 1280px) {
        left: calc(-50vw + (1260px / 2)); }
      @media (min-width: 960px) and (max-width: 1279px) {
        left: calc(-50vw + (940px / 2)); }
      @media (min-width: 640px) and (max-width: 959px) {
        left: calc(-50vw + (620px / 2)); }
      @media (min-width: 320px) and (max-width: 639px) {
        left: calc(-50vw + (300px / 2)); }
      right: auto; }
    .block-content {
      ul {
        column-count: 1;
        column-gap: 0; } } }

  .block-content-home-opening-hours {
    min-height: 0;
    position: relative;
    &::before {
      background: #EAF3FA url("../img/opening-hours-pattern.png") repeat 0 0;
      content: "";
      display: block;
      height: 100%;
      @media (min-width: 1280px) {
        left: calc(-50vw + (1260px / 2)); }
      @media (min-width: 960px) and (max-width: 1279px) {
        left: calc(-50vw + (940px / 2)); }
      @media (min-width: 640px) and (max-width: 959px) {
        left: calc(-50vw + (620px / 2)); }
      @media (min-width: 320px) and (max-width: 639px) {
        left: calc(-50vw + (300px / 2)); }
      position: absolute;
      top: 0;
      width: 100vw;
      z-index: 1; }
    .block-inner {
      position: relative;
      z-index: 2; }
    .block-content {
      h2,
      table,
      p {
        margin: 30px 0; } } }

  .block-content-home-city-more {
    @include span-columns(4);
    min-height: 220px;
    &::after {
      @media (min-width: 1280px) {
        left: calc(-50vw + (1260px / 2)); }
      @media (min-width: 960px) and (max-width: 1279px) {
        left: calc(-50vw + (940px / 2)); }
      @media (min-width: 640px) and (max-width: 959px) {
        left: calc(-50vw + (620px / 2)); }
      @media (min-width: 320px) and (max-width: 639px) {
        left: calc(-50vw + (300px / 2)); } }
    .block-content {
      line-height: 220px;
      p {
        margin: 0; } } }

  .block-highlighted-section-title {
    &::before {
      display: none; }
    .section-title {
      @include rem(font-size, 44px);
      @include rem(line-height, 44px); }
    .section-image {
      height: 510px;
      overflow: hidden;
      width: 100%;
      img {
        height: 100%;
        left: 50%;
        max-width: none;
        position: relative;
        transform: translateX(-50%); } } }

  .block-content-subsection-title {
    margin-bottom: 0;
    .page-title {
      @include rem(font-size, 44px);
      @include rem(line-height, 44px);
      margin: 0; }
    .page-informations {
      padding: 70px 0 30px 0;
      &::before {
        left: 50%;
        transform: translateX(-50%); } }
    .views-field-nothing {
      min-height: 0;
      &::before {
        left: 50%;
        transform: translateX(-50%); } }
    .views-field-field-page-image {
      left: 50%;
      transform: translateX(-50%);
      width: 100vw; } }

  .block-content-discover-history {
    .block-footer {
      position: relative;
      text-align: right; } }

  .block-content-discover-map {
    margin-bottom: 30px;
    min-height: 0;
    padding: 30px 0;
    &::after,
    &::before {
      left: 50%;
      min-height: 0;
      transform: translateX(-50%);
      width: 100vw; }
    .block-inner {
      margin: 0; } }

  .block-content-discover-come,
  .block-content-discover-tourism,
  .block-content-discover-flag,
  .block-content-discover-webcam {
    margin-bottom: 30px;
    margin-top: 30px;
    min-height: 0;
    padding: 0 0 50px 0;
    position: relative;
    &::after {
      background-color: rgba(#BBBDBE, 0.5);
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 100vw; }
    .block-image {
      img {
        max-width: none;
        width: 100%; } }
    .block-footer {
      position: relative; } }

  .block-content-discover-webcam {
    padding-bottom: 0;
    &::after {
      display: none; } }

  .block-content-markers-map {
    .map,
    .map-legend {
      float: none;
      width: 100%; } }

  .block-content-node-tree {
    .item-subsection {
      margin-bottom: 30px;
      margin-top: 30px;
      min-height: 0;
      padding: 0 0 50px 0;
      &.invisible-on-desktop {
        display: none !important; }
      .item-image {
        float: none;
        height: auto;
        width: auto;
        img {
          max-width: none;
          width: 100%; } }
      .item-informations {
        float: none;
        margin: 0;
        padding: 0;
        width: auto; }
      .item-title {
        h2 {
          @include rem(font-size, 36px);
          @include rem(line-height, 36px);
          margin: 20px 0; } }
      .item-summary {
        p {
          margin: 20px 0; } }
      .item-pages {
        margin: 20px 0; } }
    .item-page {
      margin-bottom: 30px;
      margin-top: 30px;
      min-height: 0;
      padding-top: 30px;
      position: relative;
      &::before {
        background-color: rgba(#BBBDBE, 0.5);
        top: 0;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 100vw; }
      .item-link {
        position: relative; } } }

  .block-content-news {
    .views-row {
      margin: 30px 0;
      padding-bottom: 30px; }
    .news-image {
      float: none;
      height: auto;
      margin-bottom: 15px;
      width: auto;
      img {
        max-width: none;
        width: 100%; } }
    .news-informations {
      float: none;
      padding-left: 0;
      width: auto; } }

  .block-content-events {
    .views-row {
      margin: 30px 0;
      padding-bottom: 30px; }
    .event-image {
      float: none;
      height: auto;
      margin-bottom: 15px;
      width: auto;
      img {
        max-width: none;
        width: 100%; } }
    .event-informations {
      float: none;
      padding-left: 0;
      width: auto; } }

  .block-footer-top-photos-videos,
  .block-footer-top-mag,
  .block-footer-top-webcam {
    min-height: 100px;
    height: 100px;
    width: 100%;
    .block-title {
      @extend .container;
      h2 {
        line-height: 100px;
        margin: 0; } }
    .block-image {
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        height: 100%;
        width: auto; } } }

  .block-footer-main-menu {
    display: none; }

  .block-footer-social-menu,
  .block-footer-newsletter {
    min-height: 0; }

  .block-footer-social-menu {
    border-top: 0; }

  .block-footer-newsletter {
    @include span-columns(4);
    margin-bottom: 0;
    padding-bottom: 30px; }

  .block-footer-secondary-menu {
    @include span-columns(4);
    .block-content {
      .menu {
        margin: 20px 0;
        li {
          display: block;
          line-height: normal;
          margin: 30px 0; } } } }

  .footer-logo {
    display: none; } }
