// Desktop >= 1280px
// ==================================================
@include media($desktop) {

  $max-width: 1260px;

  @import "neat";

  .container {
    @include outer-container; }

  .header-logo {
    @include span-columns(3); }

  .block-header-main-menu {
    @include span-columns(10); }

  .block-header-search {
    @include span-columns(1); }

  .block-header-accessibility {
    @include span-columns(2); }

  .block-highlighted-section-title {
    .views-field-nothing {
      @extend .container; }
    .section-informations {
      @include span-columns(6); } }

  .block-content-subsection-title {
    .views-field-nothing {
      @include span-columns(8);
      @include omega; }
    .views-field-field-page-image {
      @include span-columns(8);
      @include omega; } }

  .block-content-home-services,
  .block-content-home-agenda,
  .block-content-home-idea,
  .block-content-home-map {
    @include span-columns(4); }

  .block-content-home-map {
    @include omega; }

  .block-content-home-city {
    @include span-columns(6);
    @include omega; }

  .block-content-home-opening-hours {
    @include span-columns(6);
    @include omega; }

  .block-content-home-city-more {
    @include span-columns(4);
    @include omega; }

  .block-content-discover-history {
    @include span-columns(10); }

  .block-content-discover-map {
    @include span-columns(5);
    @include shift(1);
    @include omega; }

  .block-content-discover-come,
  .block-content-discover-tourism,
  .block-content-discover-flag,
  .block-content-discover-webcam {
    @include span-columns(4); }

  .block-content-node-tree {
    .item-page {
      @include span-columns(4);
      &:nth-child(4n) {
        @include omega; } } }

  .block-footer-main-menu {
    @include span-columns(16); }

  .block-footer-social-menu {
    @include span-columns(8); }

  .block-footer-newsletter {
    @include span-columns(8); }

  .block-footer-secondary-menu {
    @include span-columns(11); }

  .footer-logo {
    @include span-columns(3);
    @include shift(2); } }
