// ==================================================
// Typography rules
// ==================================================

@font-face {
  font-family: "Market Web";
  src: url("../fonts/MarketWeb.eot");
  src: url("../fonts/MarketWeb.eot?#iefix") format("embedded-opentype"), url("../fonts/MarketWeb.woff2") format("woff2"), url("../fonts/MarketWeb.woff") format("woff"), url("../fonts/MarketWeb.ttf") format("truetype"), url("../fonts/MarketWeb.svg#MarketWeb") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Titillium Web Regular";
  src: url("../fonts/TitilliumWeb-Regular.eot");
  src: url("../fonts/TitilliumWeb-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/TitilliumWeb-Regular.woff2") format("woff2"), url("../fonts/TitilliumWeb-Regular.woff") format("woff"), url("../fonts/TitilliumWeb-Regular.ttf") format("truetype"), url("../fonts/TitilliumWeb-Regular.svg#TitilliumWeb-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Titillium Web Bold";
  src: url("../fonts/TitilliumWeb-Bold.eot");
  src: url("../fonts/TitilliumWeb-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/TitilliumWeb-Bold.woff2") format("woff2"), url("../fonts/TitilliumWeb-Bold.woff") format("woff"), url("../fonts/TitilliumWeb-Bold.ttf") format("truetype"), url("../fonts/TitilliumWeb-Bold.svg#TitilliumWeb-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Myriad Pro Regular";
  src: url("../fonts/MyriadPro-Regular.eot");
  src: url("../fonts/MyriadPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/MyriadPro-Regular.woff2") format("woff2"), url("../fonts/MyriadPro-Regular.woff") format("woff"), url("../fonts/MyriadPro-Regular.ttf") format("truetype"), url("../fonts/MyriadPro-Regular.svg#MyriadPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Myriad Pro Bold";
  src: url("../fonts/MyriadPro-Bold.eot");
  src: url("../fonts/MyriadPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/MyriadPro-Bold.woff2") format("woff2"), url("../fonts/MyriadPro-Bold.woff") format("woff"), url("../fonts/MyriadPro-Bold.ttf") format("truetype"), url("../fonts/MyriadPro-Bold.svg#MyriadPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

html {
  font-size: 62.5%; // 1rem = 10px
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0.1);
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  @include rem(font-size, 18px);
  font-family: "Myriad Pro Regular", Arial, Verdana, sans-serif; }

a {
  color: map-get($colors, primary);
  @each $colorname, $color in $colors {
    body.section-#{$colorname} & {
      color: $color; } }
  cursor: pointer;
  text-decoration: underline;
  html.no-touchevents &:hover {
    color: map-get($colors, primary);
    text-decoration: none; } }

b, strong {
  font-family: "Myriad Pro Bold", Arial, Verdana, sans-serif;
  font-weight: bold; }

i, em {
  font-style: italic; }

u {
  @extend .text-underline; }

img {
  margin: 5px 30px 15px 30px;
  &.align-left {
    margin-left: 0; }
  &.align-right {
    margin-right: 0; }
  &.border {
    border: 3px solid rgba(map-get($colors, primary), 0.4); } }

h1, .h1 {
  @extend .clear-both;
  @include rem(font-size, 60px);
  @include rem(line-height, 60px);
  color: map-get($colors, primary);
  font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
  margin: 30px 0;
  padding: 0;
  a {
    &:hover {
      text-decoration: none; } } }

h2, .h2 {
  @extend .clear-both;
  @include rem(font-size, 40px);
  @include rem(line-height, 40px);
  color: map-get($colors, primary);
  font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
  margin: 30px 0;
  padding: 0;
  a {
    &:hover {
      text-decoration: none; } } }

h3, .h3 {
  @extend .clear-both;
  @include rem(font-size, 32px);
  @include rem(line-height, 32px);
  color: map-get($colors, search);
  font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
  margin: 30px 0;
  padding: 0;
  a {
    &:hover {
      text-decoration: none; } } }

h4, .h4 {
  @extend .clear-both;
  @include rem(font-size, 24px);
  @include rem(line-height, 24px);
  color: map-get($colors, search);
  font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
  margin: 30px 0;
  a {
    &:hover {
      text-decoration: none; } } }

h5, .h5 {
  @extend .clear-both;
  @include rem(font-size, 18px);
  @include rem(line-height, 18px);
  color: map-get($colors, search);
  font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
  margin: 30px 0;
  a {
    &:hover {
      text-decoration: none; } } }

p {
  @include rem(font-size, 18px);
  @include rem(line-height, 27px);
  color: map-get($colors, primary);
  font-family: "Myriad Pro Regular", Arial, Verdana, sans-serif;
  margin: 30px 0; }

table {
  @include rem(font-size, 18px);
  @include rem(line-height, 27px);
  margin: 60px 0;
  table-layout: fixed;
  text-align: center;
  width: 100%;
  thead {
    th {
      background-color: map-get($colors, primary);
      border: 1px solid #E4E4E4;
      color: #FFFFFF;
      font-family: "Myriad Pro Bold", Arial, Verdana, sans-serif;
      padding: 20px 40px;
      text-transform: uppercase; } }
  tbody {
    tr {
      &.odd,
      &:nth-child(odd) {
        td {
          background-color: #FFFFFF; } }
      &.even,
      &:nth-child(even) {
        td {
          background-color: #F5F5F5; } }
      th,
      td {
        background-color: #FFFFFF;
        border: 1px solid #E4E4E4;
        color: map-get($colors, primary);
        padding: 20px 40px; }
      td {
        font-family: "Myriad Pro Regular", Arial, Verdana, sans-serif; }
      th {
        font-family: "Myriad Pro Bold", Arial, Verdana, sans-serif; } } } }

blockquote {
  @include rem(font-size, 18px);
  @include rem(line-height, 27px);
  border-bottom: 1px solid map-get($colors, search);
  border-top: 1px solid map-get($colors, search);
  @each $colorname, $color in $colors {
    body.section-#{$colorname} & {
      border-color: $color; } }
  color: map-get($colors, primary);
  clear: both;
  font-family: "Myriad Pro Regular", Arial, Verdana, sans-serif;
  font-style: italic;
  overflow: hidden;
  padding: 40px 60px;
  position: relative;
  margin: 60px 0;
  &::after,
  &::before {
    @extend %icon;
    @include rem(font-size, 90px);
    @include rem(line-height, 90px);
    color: rgba(map-get($colors, search), 0.25);
    @each $colorname, $color in $colors {
      body.section-#{$colorname} & {
        color: rgba($color, 0.25); } }
    position: absolute; }
  &::after {
    bottom: 25px;
    content: icon-char(quote-right);
    right: 25px; }
  &::before {
    content: icon-char(quote-left);
    left: 25px;
    top: 25px; }
  p {
    @include rem(font-size, 16px);
    @include rem(line-height, 24px);
    color: map-get($colors, primary);
    margin: 15px; } }

ul {
  @include rem(font-size, 16px);
  @include rem(line-height, 16px);
  color: map-get($colors, primary);
  font-family: "Myriad Pro Regular", Arial, Verdana, sans-serif;
  margin: 30px 0;
  padding: 0;
  li {
    margin: 15px 0;
    padding: 0;
    &::before {
      background-color: map-get($colors, primary);
      @each $colorname, $color in $colors {
        body.section-#{$colorname} & {
          background-color: $color; } }
      content: "";
      display: inline-block;
      vertical-align: top;
      height: 14px;
      margin-right: 20px;
      width: 8px; } }
  ul {
    margin: 0 35px; } }

ol {
  @include rem(font-size, 22px);
  @include rem(line-height, 24px);
  color: map-get($colors, primary);
  @each $colorname, $color in $colors {
    body.section-#{$colorname} & {
      color: $color; } }
  font-family: "Market Web", Arial, Verdana, sans-serif;
  margin: 30px;
  padding: 0;
  li {
    list-style: decimal;
    margin: 5px 0;
    padding: 0;
    .text {
      @include rem(font-size, 16px);
      @include rem(line-height, 16px);
      color: map-get($colors, primary);
      font-family: "Myriad Pro Regular", Arial, Verdana, sans-serif;
      padding-left: 15px; } }
  ol {
    margin: 0 30px;
    padding: 0; } }

hr {
  @extend .clear-both;
  background-color: map-get($colors, search);
  border: 0;
  color: map-get($colors, search);
  height: 1px;
  margin: 30px 0; }

dt,
dd {
  @include rem(line-height, 18px);
  @include rem(line-height, 27px);
  color: map-get($colors, primary);
  margin: 30px; }

sub {
  @include rem(font-size, 11px);
  vertical-align: sub; }

sup {
  @include rem(font-size, 11px);
  vertical-align: super; }
