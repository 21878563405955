// ==================================================
// Nodes types
// ==================================================

// Node TYPE
// --------------------------------------------------
.node-TYPE {
 }  // Stuff

.page-search-node {
  .block-panels-search {
    .block-content {
      .form-item {
        display: block; } } }
  .search-form {
    font-size: 0;
    .form-item {
      @include rem(font-size, 18px);
      display: inline-block;
      label {
        text-align: left;
        width: 200px; } }
    .form-submit {
      @include rem(font-size, 18px);
      margin-left: 30px; } }
  .search-results {
    margin: 50px 0; }
  .search-result {
    margin: 50px 0;
    padding: 0 0 50px 0;
    position: relative;
    &:last-child {
      padding-bottom: 0;
      &::after {
        display: none; } }
    &::after {
      background-color: rgba(#BBBDBE, 0.5);
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      @media (min-width: 1280px) {
        left: calc(-50vw + (1260px / 2)); }
      @media (min-width: 960px) and (max-width: 1279px) {
        left: calc(-50vw + (940px / 2)); }
      @media (min-width: 640px) and (max-width: 959px) {
        left: calc(-50vw + (620px / 2)); }
      @media (min-width: 320px) and (max-width: 639px) {
        left: calc(-50vw + (320px / 2)); }
      position: absolute;
      width: 100vw; }
    &::before {
      display: none; }
    .text {
      display: block; } }
  .search-title {
    h2 {
      margin: 0;
      padding: 0; } }
  .search-link {
    a {
      @each $colorname, $color in $colors {
        body.section-#{$colorname} & {
          @extend .btn-#{$colorname}; } }
      &::after {
        @extend %icon;
        @include rem(font-size, 14px);
        @include rem(line-height, 14px);
        content: icon-char(arrow-right);
        display: inline-block;
        margin-left: 15px;
        vertical-align: middle; } } } }
