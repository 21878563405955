// Small desktop < 1280px
// ==================================================
@include media($small-desktop) {

  $max-width: 940px;

  @import "neat";

  .container {
    @include outer-container; }

  .header-logo {
    @include span-columns(2); }

  .block-header-main-menu {
    @include span-columns(9);
    .block-content {
      .menu {
        a {
          padding: 0 16px; } } } }

  .block-header-search {
    @include span-columns(1);
    @include omega; }

  .block-header-accessibility {
    @include span-columns(1);
    @include omega;
    display: none;
    .block-content {
      a {
        padding: 4px 8px; } } }

  .content {
    html.front & {
      background-size: cover; } }

  .block-highlighted-home-slideshows {
    .block-content {
      .slideshow-image {
        .field-content {
          height: 420px; }
        img {
          height: 100%;
          max-width: none;
          width: auto; } }
      .slideshow-informations {
        padding: 0 10px;
        strong {
          @include rem(font-size, 36px);
          @include rem(line-height, 50px); } } } }

  .block-highlighted-home-news {
    .block-content {
      .owl-item {
        min-height: 0;
        padding: 0 15px; }
      .views-field-field-image {
        float: none;
        height: 220px;
        overflow: hidden;
        width: 100%;
        img {
          height: auto;
          max-width: none;
          width: 100%; } }
      .views-field-nothing {
        float: none;
        width: 100%; } } }

  .block-content-home-services,
  .block-content-home-agenda,
  .block-content-home-idea,
  .block-content-home-map {
    @include span-columns(6);
    margin-bottom: 30px;
    margin-top: 30px;
    .block-inner {
      background-size: cover; } }

  .block-content-home-services,
  .block-content-home-agenda {
    margin-bottom: 0; }

  .block-content-home-agenda,
  .block-content-home-map {
    @include omega; }

  .block-highlighted-section-title {
    .views-field-nothing {
      @extend .container; }
    .section-informations {
      @include span-columns(6); }
    &::before {
      display: none; }
    .section-title {
      @include rem(font-size, 44px);
      @include rem(line-height, 44px); }
    .section-image {
      height: 510px;
      overflow: hidden;
      width: 100%;
      img {
        height: 100%;
        left: 50%;
        max-width: none;
        position: relative;
        transform: translateX(-50%); } } }

  .block-content-subsection-title {
    .page-informations {
      &::before {
        left: 50%;
        transform: translateX(-50%); } }
    .views-field-nothing {
      @include span-columns(6);
      @include omega;
      min-height: 270px;
      &::before {
        left: 50%;
        min-height: 230px;
        transform: translateX(-70%); } }
    .views-field-field-page-image {
      @include span-columns(6);
      @include omega; }
    .page-image {
      min-height: 270px;
      img {
        height: 100%;
        width: auto; } } }

  .block-content-discover-history {
    @include span-columns(7); }

  .block-content-discover-map {
    @include span-columns(5);
    @include omega; }

  .block-content-discover-come,
  .block-content-discover-tourism,
  .block-content-discover-flag,
  .block-content-discover-webcam {
    @include span-columns(4); }

  .block-content-discover-flag {
    @include omega; }

  .block-content-home-city {
    @include span-columns(6);
    @include omega; }

  .block-content-home-opening-hours {
    @include span-columns(6);
    @include omega;
    background: none;
    position: relative;
    &::after {
      background: #EAF3FA url("../img/opening-hours-pattern.png") repeat 0 0;
      content: "";
      display: block;
      left: 0;
      height: 100%;
      min-height: 440px;
      position: absolute;
      top: 0;
      width: 100vw;
      z-index: -1; } }

  .block-content-home-city-more {
    @include span-columns(6);
    @include shift(5); }

  .block-content-node-tree {
    .item-page {
      @include span-columns(4);
      &:nth-child(3n) {
        @include omega; } } }

  .block-footer-top-photos-videos,
  .block-footer-top-mag,
  .block-footer-top-webcam {
    .block-image {
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        height: 100%;
        width: auto; } } }

  .block-footer-main-menu {
    @include span-columns(12); }

  .block-footer-social-menu {
    @include span-columns(6); }

  .block-footer-newsletter {
    @include span-columns(6); }

  .block-footer-secondary-menu {
    @include span-columns(10);
    .block-content {
      .menu {
        li {
          margin-right: 15px; } } } }

  .footer-logo {
    @include span-columns(2); } }
