// ==================================================
// Rem
// Sass mixin and function to use rem units with pixel fallback
// https://github.com/pierreburel/rem
// ==================================================

$rem-baseline: 10px !default;

@function _rem($values) {
  $rem: unquote("");
  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "px" {
      $rem: append($rem, $value / ($rem-baseline / 1rem)); }
    @else {
      $rem: append($rem, $value); } }
  @return $rem; }

@mixin rem($property, $values, $important: false) {
  $important: if($important, unquote(" !important"), null);
  #{$property}: append($values, $important);
  #{$property}: append(_rem($values), $important); }


// ==================================================
// Text Replacement
// Inspired by Compass (http://compass-style.org/reference/compass/typography/text/replacement)
// ==================================================

$hide-text-direction: left !default;

@mixin hide-text($direction: $hide-text-direction) {
  @if $direction == left {
    $approximate-em-value: 12px / 1em;
    $wider-than-any-screen: -9999em;
    overflow: hidden;
    text-align: left;
    text-indent: $wider-than-any-screen * $approximate-em-value; }
  @else {
    overflow: hidden;
    text-indent: 110%;
    white-space: nowrap; } }


// ==================================================
// Horizontal, vertical or absolute centering of element within its parent
// If specified, this mixin will use negative margins based on element's
// dimensions. Else, it will rely on CSS transforms which have a lesser
// browser support but are more flexible as they are dimension-agnostic.
//
// @author Hugo Giraudel
//
// @param {Length | null} $width [null]  - Element width
// @param {Length | null} $height [null] - Element height
// ==================================================
@mixin center($height: null, $width: null) {
  left: 50%;
  position: absolute;
  top: 50%;
  @if not $width and not $height {
    transform: translate(-50%, -50%); }
  @else if $width and $height {
    height: $height;
    margin: -($height / 2) #{0 0} -($width / 2);
    width: $width; }
  @else if not $height {
    margin-left: -($width / 2);
    transform: translateY(-50%);
    width: $width; }
  @else {
    height: $height;
    margin-top: -($height / 2);
    transform: translateX(-50%); } }

// ==================================================
// Full width bar
// Extend visually an element to cover the full width of the viewport
// Inspired by https://css-tricks.com/full-browser-width-bars/
//
// @author Lisa (http://codepen.io/lemnis/pen/djtCb)
//
// @param $color - Bar background-color
// ==================================================
@mixin full-width-bar($color, $shadow) {
  position: relative;
  z-index: 0;
  &::after {
    background: $color;
    @if $shadow == true {
      box-shadow: rgba(#000, 0.2) 0 0 8px 1px; }
    content: "";
    display: block;
    height: 100%;
    @media (min-width: 1280px) {
      left: calc(-50vw + (1260px / 2)); }
    @media (min-width: 960px) and (max-width: 1279px) {
      left: calc(-50vw + (940px / 2)); }
    @media (min-width: 640px) and (max-width: 959px) {
      left: calc(-50vw + (620px / 2)); }
    @media (min-width: 320px) and (max-width: 639px) {
      left: calc(-50vw + (320px / 2)); }
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1; } }

// ==================================================
// Full width image
// Extend visually an image to cover the full width of the viewport
// Inspired by @mixin full-width-bar
//
// @param $data - Image name
// ==================================================
@mixin full-width-image($image) {
  position: relative;
  z-index: 0;
  &::before {
    background: url(#{$image}) no-repeat center center;
    background-size: cover;
    content: "";
    display: block;
    height: 100%;
    @media (min-width: 1280px) {
      left: calc(-50vw + (1260px / 2)); }
    @media (min-width: 960px) and (max-width: 1279px) {
      left: calc(-50vw + (940px / 2)); }
    @media (min-width: 640px) and (max-width: 959px) {
      left: calc(-50vw + (620px / 2)); }
    @media (min-width: 320px) and (max-width: 639px) {
      left: calc(-50vw + (320px / 2)); }
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1; } }
