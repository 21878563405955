// ==================================================
// Messages
// ==================================================

.block-content-messages {
  margin: 20px 0;
  .messages {
    @include rem(font-size, 18px);
    @include rem(line-height, 20px);
    background-image: none;
    border: 0;
    border-radius: 4px;
    color: #ffffff;
    font-family: "Myriad Pro Regular", Arial, Verdana, sans-serif;
    font-weight: bold;
    margin: 10px 0;
    padding: 10px;
    &.status {
      @extend .icon-circle-tick;
      background-color: map-get($colors, status);
      html.front & {
        background-color: map-get($colors, information); } }
    &.warning {
      @extend .icon-circle-exclamation-mark;
      background-color: map-get($colors, warning); }
    &.error {
      @extend .icon-circle-cross;
      background-color: map-get($colors, error); }

    &.information {
      @extend .icon-circle-tick;
      background-color: map-get($colors, information); }
    &:before {
      @include rem(font-size, 20px);
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle; }
    h2 {
      margin: 0; }
    a, ul {
      color: #fff; }
    a {
      text-decoration: underline; }
    ul {
      display: inline-block;
      margin: 0;
      vertical-align: middle; }
    li {
      margin: 0;
      padding: 0;
      &:before {
        display: none; } }
    p {
      color: #ffffff;
      display: inline-block;
      vertical-align: middle; }
    .krumo-root {
      ul {
        display: block;
        ul {
          margin: 0 30px; } }
      ul, a {
        color: #000; } } } }
