// ==================================================
// Node
// ==================================================

.node-unpublished {
  background-color: transparent; }

// ==================================================
// Pager
// ==================================================

.pager {
  @include rem(font-size, 0);
  margin: 50px 0;
  padding: 0;
  text-align: center;
  li {
    @include rem(font-size, 16px);
    @include rem(line-height, 24px);
    border: 1px solid map-get($colors, primary);
    border-radius: 13px;
    color: map-get($colors, primary);
    display: inline-block;
    font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
    height: 26px;
    margin: 0 0 0 10px;
    padding: 0;
    text-align: center;
    transition: all 0.2s ease;
    vertical-align: top;
    width: 26px;
    &:before {
      display: none; }
    &.pager-first,
    &.pager-last,
    &.pager-next,
    &.pager-previous {
      width: auto;
      a {
        padding: 0 10px; } }
    &.pager-current,
    html.no-touchevents &:hover {
      background-color: map-get($colors, primary);
      color: #ffffff;
      a {
        color: #ffffff; } }
    a {
      color: map-get($colors, primary);
      display: block;
      text-decoration: none; } } }

// ==================================================
// Shadowbox
// ==================================================

#sb-container {
  img {
    margin: 0; } }

// ==================================================
// Cookie
// ==================================================

#sliding-popup {
  font-family: Arial, Verdana, sans-serif;
  .popup-content {
    #popup-text {
      p {
        margin: 5px 0; } }
    #popup-buttons {
      button {
        margin-top: 7px; } } } }

// ==================================================
// Admin menu and IMCE
// ==================================================

#admin-menu,
#imce-content {
  li {
    @include rem(font-size, 11px);
    font-family: "Lucida Grande", Tahoma, Verdana, Arial, sans-serif;
    line-height: normal;
    margin: 0;
    &::before {
      display: none; } } }

// ==================================================
// Shadowbox
// ==================================================

#sb-container {
  img {
    margin: 0; } }

// ==================================================
// Tabs and actions links
// ==================================================

.block-content-tabs,
.block-content-action-links {
  text-align: right;
  ul {
    font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
    font-size: 0;
    margin: 20px 0 10px 0;
    padding: 0;
    li {
      @include rem(font-size, 12px);
      display: inline-block;
      margin: 0 0 10px 10px;
      padding: 0;
      text-transform: uppercase;
      &:before {
        display: none; }
      &.active,
      html.no-touchevents &:hover {
        a {
          background-color: map-get($colors, primary);
          color: #FFFFFF; } }
      a {
        background-color: #EEEEEE;
        color: map-get($colors, primary);
        border-radius: 4px;
        display: block;
        padding: 5px 10px;
        text-decoration: none;
        transition: all 0.2s; } } } }

.contextual-links {
  li {
    &:before {
      display: none; } } }
