// ==================================================
// Blocks
// ==================================================

.block {
  @extend .clearfix;
  img {
    height: auto;
    max-width: 100%;
    vertical-align: top; } }

.block-system {
  .links {
    a {
      @extend .btn;
      @extend .btn-small;
      @extend .btn-city;
      margin: 0 15px 15px 0; } } }

.block-panels-search {
  .block-content {
    .search-form-wrapper {
      background-color: map-get($colors, search);
      line-height: 60px; }
    .search-toggle {
      display: none; }
    .form-item {
      margin: 0 20px;
      padding: 0; }
    .form-text {
      @include rem(font-size, 18px);
      appearance: none;
      background: none;
      border-radius: 0;
      color: #FFFFFF;
      border: 0;
      border-bottom: 2px solid rgba(#FFFFFF, 0.5);
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      outline: none;
      padding: 0 0 5px 0;
      width: 100%;
      &::-webkit-input-placeholder {
        color: rgba(#FFFFFF, 0.5); }
      &::-moz-placeholder {
        color: rgba(#FFFFFF, 0.5); }
      &:-ms-input-placeholder {
        color: rgba(#FFFFFF, 0.5); }
      &:-moz-placeholder {
        color: rgba(#FFFFFF, 0.5); } }
    .form-actions {
      display: none;
      margin: 0;
      padding: 0; } } }

.block-panels-search-log {
  .block-title {
    h2 {
      @include rem(font-size, 16px);
      @include rem(line-height, 24px);
      color: #FFFFFF;
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      margin: 20px;
      padding: 0; } }
  .block-content {
    ul {
      margin: 10px 20px;
      padding: 0;
      li {
        @include rem(font-size, 18px);
        @include rem(line-height, 18px);
        margin: 0 0 20px 0;
        padding: 0;
        &::before {
          background-color: #FFFFFF;
          height: 5px;
          line-height: 1;
          margin: 0 5px 0 0;
          padding: 0;
          vertical-align: middle;
          width: 5px; }
        .views-field-q {
          font-family: "Titillium Web Bold", Arial, Verdana, sans-serif; } }
      a {
        text-decoration: none;
        html.no-touchevents &:hover {
          color: #FFFFFF; } } } } }

.block-panels-main-menu {
  border-right: 4px solid map-get($colors, search);
  height: 100%;
  .block-content {
    .menu {
      margin: 60px 0 0 0;
      padding: 0;
      li {
        @include rem(font-size, 18px);
        @include rem(line-height, 24px);
        font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
        margin: 0 -4px 0 0;
        padding: 0;
        text-transform: uppercase;
        transition: all 0.2s;
        &::before {
          background-color: rgba(#000000, 0.2);
          content: "";
          display: block;
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          transition: opacity 0.2s, transform 0.2s, visibility 0.2s;
          visibility: hidden;
          width: 100%;
          z-index: 2; }
        &.open {
          &::before {
            opacity: 1;
            visibility: visible; }
          ul {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            visibility: visible; } }
        &.last {
          margin-bottom: 15px; }
        @each $colorname, $color in $colors {
          &.menu-#{$colorname} {
            ul {
              background-color: $color;
              &::after {
                background-color: $color; } }
            a {
              &::after {
                background-color: $color; } } } }
        br {
          content: "";
          display: inline-block;
          width: 5px; }
        > ul {
          overflow-y: auto;
          -webkit-overflow-scrolling: touch; }
        a {
          display: block;
          padding: 15px;
          position: relative;
          text-decoration: none;
          html.no-touchevents &:hover {
            color: #FFFFFF; }
          &::after {
            background: red;
            content: "";
            height: 100%;
            right: 0;
            position: absolute;
            top: 0;
            width: 4px; } } }
      ul {
        box-shadow: 0 2px 10px 0 rgba(#000000, 0.5);
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.2s, transform 0.2s, visibility 0.2s;
        transform: translate3d(220px, 0, 0);
        visibility: hidden;
        width: 220px;
        z-index: 3;
        .close {
          @extend .icon-arrow-left;
          @include rem(font-size, 16px);
          background-color: rgba(#000000, 0.1);
          border: 1px solid #FFFFFF;
          border-radius: 20px;
          color: #FFFFFF;
          cursor: pointer;
          display: block;
          height: 40px;
          line-height: 40px;
          margin: 15px;
          text-align: center;
          text-decoration: none;
          width: 40px; }
        ul {
          background-color: transparent;
          box-shadow: none;
          margin: 0;
          padding: 0;
          position: relative;
          top: 0;
          width: auto; }
        li {
          @include rem(font-size, 18px);
          @include rem(line-height, 24px);
          display: block;
          font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
          margin: 0;
          text-transform: none;
          &.special {
            font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
            margin-bottom: 20px;
            padding-bottom: 20px;
            position: relative;
            text-transform: uppercase;
            &::after {
              background-color: rgba(#FFFFFF, 0.5);
              bottom: 0;
              content: "";
              display: block;
              height: 2px;
              left: 15px;
              position: absolute;
              right: 15px;
              width: auto; }
            br {
              display: block;
              width: auto; } }
          &.has-subsection {
            @include rem(font-size, 24px);
            font-family: "Market Web", Arial, Verdana, sans-serif;
            margin: 0;
            padding: 0;
            &.last {
              margin-bottom: 15px; }
            ul {
              margin: 10px 0;
              padding: 0; }
            li {
              margin: 0;
              padding: 0; } }
          a {
            display: block;
            margin: 0;
            padding: 8px 30px 8px 15px;
            &::after {
              @extend %icon;
              @include rem(font-size, 14px);
              display: block;
              content: icon-char(arrow-right);
              height: auto;
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
              width: auto; } } } } } } }

.block-header-main-menu {
  &.contextual-links-region {
    position: static; }
  .block-content {
    .menu {
      font-size: 0;
      margin: 0;
      padding: 0;
      li {
        @include rem(font-size, 18px);
        display: inline-block;
        font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
        line-height: calc(#{$header_height} + 4px);
        margin: 0;
        padding: 0;
        text-align: center;
        text-transform: uppercase;
        transition: all 0.2s;
        vertical-align: middle;
        &.has-subsection {
          ul {
            column-count: 1;
            column-gap: 0; } }
        &::before {
          display: none; }
        html.no-touchevents &:hover {
          ul {
            opacity: 1;
            visibility: visible; } }
        @each $colorname, $color in $colors {
          &.menu-#{$colorname} {
            html.no-touchevents &:hover {
              background-color: $color; }
            ul {
              background-color: $color;
              &::after {
                background-color: $color; } }
            a {
              &::after {
                background-color: $color; } } } }
        .text {
          @include rem(line-height, 24px);
          display: inline-block;
          vertical-align: middle; }
        ul {
          column-count: 2;
          column-gap: 60px;
          left: 0;
          margin: 0;
          opacity: 0;
          padding: 20px 0;
          position: absolute;
          text-align: left;
          top: calc(100% + 4px);
          transition: opacity 0.2s, visibility 0.2s;
          visibility: hidden;
          &::after {
            box-shadow: -10px 5px 10px 0 rgba(#000000, 0.1);
            content: "";
            display: block;
            @media (min-width: 1280px) {
              left: calc(-50vw + (1260px / 2)); }
            @media (min-width: 960px) and (max-width: 1279px) {
              left: calc(-50vw + (940px / 2)); }
            @media (min-width: 640px) and (max-width: 959px) {
              left: calc(-50vw + (620px / 2)); }
            @media (min-width: 320px) and (max-width: 639px) {
              left: calc(-50vw + (300px / 2)); }
            height: 120%;
            position: absolute;
            top: 0;
            width: 100vw;
            z-index: -1; }
          ul {
            background-color: transparent;
            column-count: 1;
            column-gap: 0;
            height: auto;
            min-height: auto;
            padding: 0;
            position: relative;
            top: 0;
            width: auto;
            &::after {
              display: none; } }
          li {
            border-left: 2px solid rgba(#FFFFFF, 0.5);
            display: block;
            font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
            line-height: 1;
            padding: 8px 0 8px 20px;
            text-align: left;
            text-transform: none;
            &.has-subsection {
              @include rem(font-size, 24px);
              border-left: 0;
              display: inline-block;
              font-family: "Market Web", Arial, Verdana, sans-serif;
              margin: 0 60px 0 0;
              padding-left: 0;
              vertical-align: top;
              ul {
                margin: 15px 0; }
              li {
                display: block;
                margin: 0; } }
            a {
              margin: 0;
              padding: 0;
              &::after {
                display: none; } } } } }
      a {
        color: #FFFFFF;
        display: block;
        height: 100%;
        padding: 0 18px;
        position: relative;
        text-decoration: none;
        &::after {
          bottom: -4px;
          content: "";
          height: 4px;
          left: 0;
          position: absolute;
          width: 100%; } } } } }

.block-header-search {
  &.contextual-links-region {
    position: static; }
  .search-form {
    html.no-touchevents &:hover {
      .search-form-wrapper {
        opacity: 1;
        visibility: visible; }
      .search-toggle {
        background-color: map-get($colors, search); } }
    .search-form-wrapper {
      background-color: map-get($colors, search);
      font-size: 0;
      left: 0;
      line-height: 1;
      opacity: 0;
      padding: 20px 80px;
      position: absolute;
      transition: all 0.2s;
      top: calc(100% + 4px);
      visibility: hidden;
      width: 100%;
      &::after {
        background-color: map-get($colors, search);
        content: "";
        display: block;
        @media (min-width: 1280px) {
          left: calc(-50vw + (1260px / 2)); }
        @media (min-width: 960px) and (max-width: 1279px) {
          left: calc(-50vw + (940px / 2)); }
        @media (min-width: 640px) and (max-width: 959px) {
          left: calc(-50vw + (620px / 2)); }
        @media (min-width: 320px) and (max-width: 639px) {
          left: calc(-50vw + (300px / 2)); }
        height: 100%;
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: -1; }
      .form-item {
        display: inline-block;
        margin: 0;
        vertical-align: top;
        width: 100%; }
      .form-text {
        @include rem(font-size, 32px);
        background: none;
        color: #FFFFFF;
        border: 0;
        border-bottom: 2px solid rgba(#FFFFFF, 0.5);
        font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
        outline: none;
        padding: 0 0 5px 0;
        width: 100%;
        &::-webkit-input-placeholder {
          color: rgba(#FFFFFF, 0.5); }
        &::-moz-placeholder {
          color: rgba(#FFFFFF, 0.5); }
        &:-ms-input-placeholder {
          color: rgba(#FFFFFF, 0.5); }
        &:-moz-placeholder {
          color: rgba(#FFFFFF, 0.5); } }
      .form-actions {
        display: none;
        margin: 0;
        padding: 0; } } }
  .search-toggle {
    @include rem(font-size, 27px);
    display: block;
    color: #FFFFFF;
    height: $header_height + 4px;
    line-height: $header_height + 4px;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s;
    width: 100%;
    .text {
      display: none; }
    html.no-touchevents &:hover {
      color: #FFFFFF; } } }

.block-header-accessibility {
  .block-content {
    line-height: $header_height;
    text-align: center;
    ul {
      background-color: rgba(#000000, 0.1);
      display: inline-block;
      margin: 0;
      padding: 0;
      vertical-align: middle;
      li {
        @include rem(font-size, 16px);
        @include rem(line-height, 16px);
        display: inline-block;
        margin: 0;
        padding: 0;
        &::before {
          display: none; } } }
    a {
      color: #FFFFFF;
      display: block;
      font-family: "Myriad Pro Bold", Arial, Verdana, sans-serif;
      padding: 8px;
      text-decoration: none;
      html.no-touchevents &:hover {
        color: #FFFFFF; } } } }

.block-highlighted-home-slideshows {
  .block-content {
    .owl-item,
    .item-even,
    .item-odd {
      overflow: hidden;
      position: relative; }
    .slideshow-image {
      img {
        margin: 0;
        vertical-align: top;
        width: 100%; } }
    .slideshow-informations {
      color: #FFFFFF;
      left: 0;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      strong {
        @include rem(font-size, 90px);
        @include rem(line-height, 100px);
        font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
        font-weight: bold; }
      i {
        display: block;
        font-family: "Market Web", Arial, Verdana, sans-serif;
        font-weight: normal; } } }
  .slideshow-btn {
    margin: 60px 0 0 0;
    a {
      @extend .btn;
      @extend .btn-discover; } }
  .slideshow-link {
    margin: 30px;
    text-align: center;
    a {
      @extend .icon-arrow-bottom;
      @include rem(font-size, 10px);
      background-color: rgba(#000000, 0.1);
      border: 1px solid #FFFFFF;
      border-radius: 25px;
      color: #FFFFFF;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      text-decoration: none;
      width: 50px;
      html.no-touchevents & {
        color: #FFFFFF; }
      .text {
        display: none; } } } }

.block-highlighted-home-news {
  background-color: #96B6CB;
  .block-title {
    display: none;
    h2 {
      margin: 0;
      padding: 0; } }
  .block-content {
    .owl-carousel {
      margin: 50px 0 20px 0; }
    .owl-item {
      min-height: 290px;
      padding: 0 50px;
      overflow: hidden;
      position: relative;
      > div {
        @extend .clearfix;
        background-color: #FFFFFF; } }
    .views-field-field-image {
      background-color: rgba(#BBBDBE, 0.5);
      float: left;
      height: 290px;
      width: 290px; }
    .views-field-nothing {
      float: left;
      text-align: center;
      width: calc(100% - 290px);
      .field-content {
        @extend .clearfix; } }
    .item-image {
      img {
        margin: 0;
        vertical-align: top; } }
    .item-date {
      p {
        @include rem(font-size, 18px);
        @include rem(line-height, 32px);
        color: map-get($colors, discover);
        font-family: "Market Web", Arial, Verdana, sans-serif;
        margin: 20px 40px 0 40px;
        padding: 0; } }
    .item-title {
      position: relative;
      h3 {
        @include rem(font-size, 24px);
        @include rem(line-height, 32px);
        color: map-get($colors, primary);
        font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
        margin: 0 40px 20px 40px;
        padding: 0;
        text-align: center;
        text-transform: uppercase; }
      &::after {
        background-color: rgba(#BBBDBE, 0.5);
        content: "";
        display: inline-block;
        height: 2px;
        width: 230px; } }
    .item-body {
      p {
        @include rem(font-size, 16px);
        @include rem(line-height, 24px);
        color: map-get($colors, primary);
        font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
        margin: 20px 40px;
        padding: 0; } }
    .views-field-view-node {
      @include hide-text;
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      a {
        display: block;
        height: 100%; } }
    .owl-controls {
      margin-top: 50px;
      .owl-page {
        margin: 0 5px;
        &.active {
          span {
            background-color: #FFFFFF; } }
        span {
          background: none;
          border: 2px solid #FFFFFF;
          height: 14px;
          margin: 0;
          opacity: 1;
          transition: all 0.2s;
          width: 14px; } } } }
  .block-footer {
    text-align: center;
    .block-more-link {
      display: block;
      margin: 0 0 30px 0;
      a {
        @include rem(font-size, 18px);
        @include rem(line-height, 27px);
        color: #FFFFFF;
        font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
        text-decoration: none;
        &::after {
          @extend %icon;
          @include rem(font-size, 14px);
          content: icon-char(arrow-right);
          display: inline-block;
          margin-left: 25px;
          vertical-align: middle; } } } } }

.block-highlighted-section-title {
  position: relative;
  .views-field-nothing {
    position: relative;
    z-index: 2; }
  .section-informations {
    background-color: map-get($colors, primary);
    color: #FFFFFF;
    height: 100%;
    min-height: 510px;
    opacity: 0;
    padding: 40px 0;
    position: relative;
    transition: opacity 0.2s;
    @each $colorname, $color in $colors {
      body.section-#{$colorname} & {
        background-color: $color; } }
    body.loaded & {
      opacity: 1; }
    &::after,
    &::before {
      background: transparent url("../img/section-pattern.png") repeat-x 0 0;
      content: "";
      display: block;
      height: 40px;
      left: 0;
      position: absolute;
      width: 100%; }
    &::after {
      bottom: 0; }
    &::before {
      top: 0; } }
  .section-title {
    @include rem(font-size, 54px);
    @include rem(line-height, 54px);
    color: #FFFFFF;
    font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
    margin: 30px;
    padding: 0; }
  .section-summary {
    p {
      @include rem(font-size, 18px);
      @include rem(line-height, 27px);
      color: #FFFFFF;
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      margin: 30px;
      padding: 0; } }
  .section-link {
    margin: 30px;
    text-align: center;
    a {
      @extend .icon-arrow-bottom;
      @include rem(font-size, 10px);
      background-color: rgba(#000000, 0.1);
      border: 1px solid #FFFFFF;
      border-radius: 25px;
      color: #FFFFFF;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      text-decoration: none;
      width: 50px;
      html.no-touchevents & {
        color: #FFFFFF; }
      .text {
        display: none; } } }
  .section-image {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    img {
      margin: 0;
      max-width: none;
      vertical-align: top; } } }

.block-content-breadcrumb {
  margin: 20px 0;
  .breadcrumb {
    @include rem(font-size, 16px);
    @include rem(line-height, 24px);
    color: map-get($colors, primary);
    font-family: "Myriad Pro Regular", Arial, Verdana, sans-serif;
    .breadcrumb-item {
      &:first-child {
        margin-right: 5px;
        a {
          @extend .icon-home;
          color: map-get($colors, search);
          span {
            display: none; }
          &::before {
            display: inline-block;
            margin-top: -5px;
            vertical-align: middle; } } }
      a {
        color: map-get($colors, search);
        text-decoration: none;
        transition: all 0.2s;
        &:hover {
          color: map-get($colors, primary); } } }
    .breadcrumb-spacer {
      background-color: rgba(map-get($colors, primary), 0.1);
      border-radius: 7px;
      display: inline-block;
      height: 14px;
      line-height: 14px;
      margin: 0 10px;
      text-align: center;
      width: 14px; } } }

.block-content-subsection-title {
  margin-bottom: 30px;
  position: relative;
  .views-row {
    @extend .clearfix; }
  .views-field-nothing {
    min-height: 280px;
    &::before {
      background-color: map-get($colors, primary);
      @each $colorname, $color in $colors {
        body.section-#{$colorname} & {
          background-color: $color; } }
      body.loaded & {
        opacity: 1; }
      content: "";
      display: block;
      left: calc(-50vw);
      height: 100%;
      min-height: 280px;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.2s;
      width: 100vw;
      z-index: 1; } }
  .page-informations {
    padding: 40px 0;
    position: relative;
    z-index: 2;
    &::before {
      background: transparent url("../img/section-pattern.png") repeat-x right 0;
      content: "";
      display: block;
      height: 40px;
      left: calc(-50vw);
      position: absolute;
      top: 0;
      width: 100vw; } }
  .views-field-field-page-image {
    position: relative;
    z-index: 2; }
  .page-title {
    @include rem(font-size, 64px);
    @include rem(line-height, 74px);
    color: #FFFFFF;
    font-family: "Market Web", Arial, Verdana, sans-serif;
    margin: 40px 20px 0 0;
    padding: 0; }
  .page-image {
    img {
      margin: 0;
      vertical-align: top; } } }

.block-content-home-services,
.block-content-home-agenda,
.block-content-home-idea,
.block-content-home-map {
  margin-bottom: 80px;
  margin-top: 80px;
  position: relative;
  .block-inner {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 10px 10px;
    min-height: 360px;
    padding: 10px;
    position: relative;
    &::before {
      border: 1px solid rgba(#FFFFFF, 0.3);
      bottom: 10px;
      content: "";
      display: block;
      left: 10px;
      pointer-events: none;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2; } }
  .block-title {
    @extend .clearfix;
    h2 {
      color: #FFFFFF;
      margin: 60px 20px 30px 20px;
      padding: 0;
      text-align: center;
      &::before {
        @include rem(font-size, 60px);
        @include rem(line-height, 60px);
        display: block;
        margin-bottom: 20px; }
      .text {
        @include rem(font-size, 28px);
        @include rem(line-height, 40px);
        display: block;
        font-family: "Titillium Web Regular", Arial, Verdana, sans-serif; } } }
  .block-content {
    p {
      @include rem(font-size, 14px);
      @include rem(line-height, 21px);
      color: #FFFFFF;
      font-family: "Myriad Pro Regular", Arial, Verdana, sans-serif;
      margin: 20px;
      padding: 0;
      text-align: center; } }
  .block-footer {
    .block-more-link {
      a {
        @include hide-text;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        text-indent: 200%;
        top: 0;
        width: 100%;
        z-index: 3; } } } }

.block-content-home-services {
  background-color: map-get($colors, city);
  .block-inner {
    background-image: url("../img/home-services-pattern.png"); }
  .block-title {
    h2 {
      @extend .icon-information; } } }

.block-content-home-agenda {
  background-color: map-get($colors, live);
  .block-inner {
    background-image: url("../img/home-agenda-pattern.png"); }
  .block-title {
    h2 {
      @extend .icon-calendar; } } }

.block-content-home-idea {
  background-color: map-get($colors, primary);
  .block-inner {
    background-image: url("../img/home-idea-pattern.png"); }
  .block-title {
    h2 {
      @extend .icon-bulb; } } }

.block-content-home-map {
  background-color: map-get($colors, urban);
  .block-inner {
    background-image: url("../img/home-map-pattern.png"); }
  .block-title {
    h2 {
      @extend .icon-marker; } } }

.block-content-home-city {
  background-color: #FFFFFF;
  clear: both;
  min-height: 470px;
  position: relative;
  z-index: 1;
  &::before {
    background-color: #FFFFFF;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 100%;
    top: 0;
    width: 100vw;
    z-index: 1; }
  .block-inner {
    position: relative;
    z-index: 2; }
  .block-content {
    @extend .clearfix;
    h2 {
      @include rem(font-size, 36px);
      @include rem(line-height, 40px);
      font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
      margin: 60px 0 0 0;
      padding: 0; }
    p {
      @include rem(font-size, 28px);
      @include rem(line-height, 40px);
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      margin: 0;
      padding: 0; }
    ul {
      @include rem(font-size, 15px);
      @include rem(line-height, 22px);
      column-count: 2;
      column-gap: 20px;
      margin: 40px 0;
      padding: 0;
      li {
        margin: 0 0 20px 0;
        padding: 0;
        &.highlighted {
          color: map-get($colors, search) !important;
          strong {
            color: map-get($colors, search) !important; } }
        &::before {
          display: none; } }
      strong {
        color: map-get($colors, city); }
      a {
        text-decoration: none; } } } }

.block-content-home-opening-hours {
  background: #EAF3FA url("../img/opening-hours-pattern.png") repeat 0 0;
  min-height: 470px;
  position: relative;
  z-index: 2;
  .block-content {
    @extend .clearfix;
    h2 {
      @include rem(font-size, 21px);
      @include rem(line-height, 32px);
      color: map-get($colors, city);
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      margin: 60px 70px 30px 70px;
      padding: 0; }
    table {
      @include rem(font-size, 15px);
      @include rem(line-height, 30px);
      background: none;
      border: 0;
      margin: 30px 70px;
      width: auto;
      tbody {
        td,
        th {
          background: none;
          border: 0;
          padding: 0; }
        td {
          text-align: left; }
        th {
          padding-right: 20px;
          text-align: right; } } }
    p {
      @include rem(font-size, 16px);
      @include rem(line-height, 24px);
      font-family: "Myriad Pro Regular", Arial, Verdana, sans-serif;
      margin: 30px 70px;
      padding: 0; } } }

.block-content-home-city-more {
  background-color: map-get($colors, city);
  min-height: 470px;
  position: relative;
  &::after {
    background-color: map-get($colors, city);
    content: "";
    display: block;
    left: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1; }
  .block-inner {
    height: 100%;
    position: relative;
    z-index: 2; }
  .block-content {
    height: 100%;
    line-height: 470px;
    h2 {
      color: #FFFFFF;
      margin: 0;
      padding: 0; }
    p {
      @include rem(font-size, 36px);
      @include rem(line-height, 40px);
      color: #FFFFFF;
      display: inline-block;
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      margin: 0 0 0 70px;
      padding: 0;
      vertical-align: middle;
      a {
        display: block;
        html.no-touchevents &:hover {
          color: #FFFFFF; }
        &::after {
          @extend %icon;
          @include rem(font-size, 20px);
          border: 1px solid #FFFFFF;
          border-radius: 25px;
          content: icon-char(arrow-right);
          display: block;
          height: 50px;
          line-height: 50px;
          margin-top: 20px;
          text-align: center;
          width: 50px; } } }
    a {
      color: #FFFFFF;
      text-decoration: none; } } }

.block-content-news {
  .views-row {
    margin: 50px 0;
    padding: 0 0 50px 0;
    position: relative;
    &:last-child {
      padding-bottom: 0;
      &::after {
        display: none; } }
    &::after {
      background-color: rgba(#BBBDBE, 0.5);
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      @media (min-width: 1280px) {
        left: calc(-50vw + (1260px / 2)); }
      @media (min-width: 960px) and (max-width: 1279px) {
        left: calc(-50vw + (940px / 2)); }
      @media (min-width: 640px) and (max-width: 959px) {
        left: calc(-50vw + (620px / 2)); }
      @media (min-width: 320px) and (max-width: 639px) {
        left: calc(-50vw + (300px / 2)); }
      position: absolute;
      width: 100vw; } }
  .news-inner {
    @extend .clearfix; }
  .news-image {
    background-color: rgba(#BBBDBE, 0.5);
    float: left;
    height: 290px;
    width: 290px;
    img {
      margin: 0;
      vertical-align: top; } }
  .news-informations {
    float: left;
    padding-left: 110px;
    width: calc(100% - 290px); }
  .news-date {
    p {
      @include rem(font-size, 18px);
      @include rem(line-height, 32px);
      color: map-get($colors, discover);
      font-family: "Market Web", Arial, Verdana, sans-serif;
      margin: 0;
      padding: 0; } }
  .news-title {
    h2 {
      @include rem(font-size, 40px);
      @include rem(line-height, 40px);
      @each $colorname, $color in $colors {
        body.section-#{$colorname} & {
          color: $color; } }
      font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
      margin: 10px 0 30px 0; } }
  .news-body {
    p {
      @include rem(font-size, 18px);
      @include rem(line-height, 27px);
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      margin: 15px 0; } }
  .news-link {
    margin: 30px 0;
    a {
      @extend .btn;
      @extend .btn-small;
      @each $colorname, $color in $colors {
        body.section-#{$colorname} & {
          @extend .btn-#{$colorname}; } }
      &::after {
        @extend %icon;
        @include rem(font-size, 14px);
        @include rem(line-height, 14px);
        content: icon-char(arrow-right);
        display: inline-block;
        margin-left: 15px;
        vertical-align: middle; } } } }

.block-content-events {
  .views-row {
    margin: 50px 0;
    padding: 0 0 50px 0;
    position: relative;
    &:last-child {
      padding-bottom: 0;
      &::after {
        display: none; } }
    &::after {
      background-color: rgba(#BBBDBE, 0.5);
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      @media (min-width: 1280px) {
        left: calc(-50vw + (1260px / 2)); }
      @media (min-width: 960px) and (max-width: 1279px) {
        left: calc(-50vw + (940px / 2)); }
      @media (min-width: 640px) and (max-width: 959px) {
        left: calc(-50vw + (620px / 2)); }
      @media (min-width: 320px) and (max-width: 639px) {
        left: calc(-50vw + (300px / 2)); }
      position: absolute;
      width: 100vw; } }
  .event-inner {
    @extend .clearfix; }
  .event-image {
    background-color: rgba(#BBBDBE, 0.5);
    float: left;
    height: 290px;
    width: 290px;
    img {
      margin: 0;
      vertical-align: top; } }
  .event-informations {
    float: left;
    padding-left: 110px;
    width: calc(100% - 290px); }
  .event-date {
    @include rem(font-size, 18px);
    @include rem(line-height, 32px);
    color: map-get($colors, discover);
    font-family: "Market Web", Arial, Verdana, sans-serif;
    margin: 0;
    padding: 0; }
  .event-title {
    h2 {
      @include rem(font-size, 40px);
      @include rem(line-height, 40px);
      @each $colorname, $color in $colors {
        body.section-#{$colorname} & {
          color: $color; } }
      font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
      margin: 10px 0 30px 0; } }
  .event-body {
    p {
      @include rem(font-size, 18px);
      @include rem(line-height, 27px);
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      margin: 15px 0; } }
  .event-link {
    margin: 30px 0;
    a {
      @extend .btn;
      @extend .btn-small;
      @each $colorname, $color in $colors {
        body.section-#{$colorname} & {
          @extend .btn-#{$colorname}; } }
      &::after {
        @extend %icon;
        @include rem(font-size, 14px);
        @include rem(line-height, 14px);
        content: icon-char(arrow-right);
        display: inline-block;
        margin-left: 15px;
        vertical-align: middle; } } } }

.block-content-discover-history {
  min-height: 440px;
  position: relative;
  .block-title {
    h2 {
      @include rem(font-size, 32px);
      @include rem(line-height, 32px);
      color: map-get($colors, primary);
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      margin: 50px 0;
      i {
        @include rem(font-size, 50px);
        @include rem(line-height, 50px);
        display: inline-block;
        font-family: "Market Web", Arial, Verdana, sans-serif;
        padding-right: 8px; } } }
  .block-content {
    p {
      @include rem(font-size, 16px);
      @include rem(line-height, 24px);
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      margin: 30px 0; } }
  .block-footer {
    bottom: 0;
    position: absolute;
    right: 0;
    .block-more-link {
      a {
        color: map-get($colors, primary);
        display: block;
        font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
        padding: 0 15px 23px 15px;
        text-decoration: none;
        text-transform: uppercase;
        &::after {
          background-color: map-get($colors, discover);
          bottom: 0;
          content: "";
          display: block;
          height: 40px;
          position: absolute;
          right: 0;
          width: 5px; } } } } }

.block-content-discover-map {
  min-height: 440px;
  position: relative;
  &::after {
    background: #EAF3FA url("../img/opening-hours-pattern.png") repeat 0 0;
    content: "";
    display: block;
    left: 0;
    height: 100%;
    min-height: 440px;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1; }
  &::before {
    background-color: rgba(#BBBDBE, 0.5);
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    @media (min-width: 1280px) {
      left: calc(-50vw - (1260px / 2)); }
    @media (min-width: 960px) and (max-width: 1279px) {
      left: calc(-50vw - (940px / 2)); }
    @media (min-width: 640px) and (max-width: 959px) {
      left: calc(-50vw - (620px / 2)); }
    @media (min-width: 320px) and (max-width: 639px) {
      left: calc(-50vw - (320px / 2)); }
    position: absolute;
    width: 200vw; }
  .block-inner {
    background: map-get($colors, urban) url("../img/home-map-pattern.png") no-repeat 10px 10px;
    min-height: 360px;
    margin: 40px 80px;
    padding: 10px;
    position: relative;
    width: 300px;
    &::before {
      border: 1px solid rgba(#FFFFFF, 0.3);
      bottom: 10px;
      content: "";
      display: block;
      left: 10px;
      pointer-events: none;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2; } }
  .block-title {
    @extend .clearfix;
    h2 {
      @extend .icon-marker;
      color: #FFFFFF;
      margin: 60px 20px 30px 20px;
      padding: 0;
      text-align: center;
      &::before {
        @include rem(font-size, 60px);
        @include rem(line-height, 60px);
        display: block;
        margin-bottom: 20px; }
      .text {
        @include rem(font-size, 28px);
        @include rem(line-height, 40px);
        display: block;
        font-family: "Titillium Web Regular", Arial, Verdana, sans-serif; } } }
  .block-content {
    p {
      @include rem(font-size, 14px);
      @include rem(line-height, 21px);
      color: #FFFFFF;
      font-family: "Myriad Pro Regular", Arial, Verdana, sans-serif;
      margin: 20px;
      padding: 0;
      text-align: center; } }
  .block-footer {
    .block-more-link {
      a {
        @include hide-text;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        text-indent: 200%;
        top: 0;
        width: 100%;
        z-index: 3; } } } }

.block-content-discover-come,
.block-content-discover-tourism,
.block-content-discover-flag,
.block-content-discover-webcam {
  min-height: 280px;
  margin-bottom: 50px;
  margin-top: 50px;
  position: relative;
  .block-title {
    h2 {
      @include rem(font-size, 32px);
      @include rem(line-height, 32px);
      color: map-get($colors, primary);
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      margin: 0 0 30px 0; } }
  .block-content {
    p {
      @include rem(font-size, 16px);
      @include rem(line-height, 24px);
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      margin: 30px 0; } }
  .block-image {
    img {
      margin: 0;
      vertical-align: top; } }
  .block-footer {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    .block-more-link {
      a {
        @extend .btn;
        @extend .btn-small;
        @extend .btn-discover;
        &::after {
          @extend %icon;
          @include rem(font-size, 14px);
          @include rem(line-height, 14px);
          content: icon-char(arrow-right);
          display: inline-block;
          margin-left: 15px;
          vertical-align: middle; } } } } }

.block-content-markers-map {
  margin-bottom: 30px;
  .block-content {
    @extend .clearfix;
    background-color: rgba(#BBBDBE, 0.15); }
  .map {
    @extend .clearfix;
    background-color: rgba(#BBBDBE, 0.5);
    float: left;
    height: 500px;
    margin: 0;
    width: 70%;
    p {
      margin: 20px; }
    img {
      margin: 0;
      max-width: none; } }
  .map-legend {
    @extend .clearfix;
    float: right;
    padding: 0;
    width: 30%;
    h2 {
      @include rem(font-size, 28px);
      @include rem(line-height, 28px);
      margin: 20px; }
    p {
      margin: 20px; }
    ul {
      font-size: 0;
      margin: 0;
      padding: 0; }
    li {
      cursor: pointer;
      display: inline-block;
      margin: 0;
      padding: 20px;
      transition: all 0.2s;
      vertical-align: top;
      width: 50%;
      &.inactive {
        opacity: 0.5; }
      &::before {
        display: none; } }
    .icon {
      display: inline-block;
      height: 40px;
      margin: 0 10px 0 0;
      vertical-align: middle; }
    .text {
      @include rem(font-size, 16px);
      @include rem(line-height, 24px);
      display: inline-block;
      font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
      vertical-align: middle;
      width: calc(100% - 50px); } }
  .map-infowindow {
    p {
      margin: 10px 0;
      padding: 0; } } }

.block-content-node-tree {
  .item-inner {
    @extend .clearfix; }
  .item-subsection {
    margin: 50px 0;
    padding: 0 0 50px 0;
    position: relative;
    &:last-child {
      padding-bottom: 0;
      &::after {
        display: none; } }
    &::after {
      background-color: rgba(#BBBDBE, 0.5);
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      @media (min-width: 1280px) {
        left: calc(-50vw + (1260px / 2)); }
      @media (min-width: 960px) and (max-width: 1279px) {
        left: calc(-50vw + (940px / 2)); }
      @media (min-width: 640px) and (max-width: 959px) {
        left: calc(-50vw + (620px / 2)); }
      @media (min-width: 320px) and (max-width: 639px) {
        left: calc(-50vw + (300px / 2)); }
      position: absolute;
      width: 100vw; }
    .item-image {
      background-color: rgba(#BBBDBE, 0.5);
      float: left;
      height: 330px;
      width: 460px; }
    .item-informations {
      float: left;
      padding-left: 110px;
      width: calc(100% - 460px); }
    .item-title {
      h2 {
        @include rem(font-size, 50px);
        @include rem(line-height, 50px);
        @each $colorname, $color in $colors {
          body.section-#{$colorname} & {
            color: $color; } }
        font-family: "Market Web", Arial, Verdana, sans-serif;
        margin: 10px 0 30px 0; } }
    .item-summary {
      p {
        @include rem(font-size, 18px);
        @include rem(line-height, 27px);
        font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
        margin: 30px 0; } }
    .item-pages {
      font-family: "Myriad Pro Regular", Arial, Verdana, sans-serif;
      font-size: 0;
      margin: 30px 0;
      padding: 0;
      li {
        @include rem(font-size, 16px);
        @include rem(line-height, 16px);
        @each $colorname, $color in $colors {
          body.section-#{$colorname} & {
            color: $color; } }
        display: inline-block;
        margin: 0 10px 10px 0;
        padding: 0;
        vertical-align: middle;
        &:last-child {
          &::after {
            display: none; } }
        &::after {
          content: "|";
          display: inline-block;
          padding: 0 0 0 10px;
          vertical-align: middle; }
        &::before {
          display: none; } }
      a {
        text-decoration: none;
        transition: all 0.2s;
        &:hover {
          @each $colorname, $color in $colors {
            body.section-#{$colorname} & {
              color: darken($color, 10%); } } } } } }
  .item-page {
    min-height: 280px;
    margin-bottom: 50px;
    margin-top: 50px;
    position: relative;
    .item-title {
      h2 {
        @include rem(font-size, 32px);
        @include rem(line-height, 32px);
        color: map-get($colors, primary);
        font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
        margin: 0 0 30px 0; } }
    .item-summary {
      p {
        @include rem(font-size, 16px);
        @include rem(line-height, 24px);
        font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
        margin: 30px 0; } }
    .item-link {
      bottom: 0;
      left: 0;
      position: absolute;
      width: 100%; } }
  .item-image {
    img {
      margin: 0;
      vertical-align: top; } }
  .item-title {
    h2 {
      margin: 0;
      padding: 0; } }
  .item-summary {
    p {
      margin: 0;
      padding: 0; } }
  .item-link {
    .btn {
      @each $colorname, $color in $colors {
        body.section-#{$colorname} & {
          @extend .btn-#{$colorname}; } }
      &::after {
        @extend %icon;
        @include rem(font-size, 14px);
        @include rem(line-height, 14px);
        content: icon-char(arrow-right);
        display: inline-block;
        margin-left: 15px;
        vertical-align: middle; } } } }

.block-footer-top-photos-videos,
.block-footer-top-mag,
.block-footer-top-webcam {
  @include rem(font-size, 18px);
  background-color: #7C8E9B;
  display: inline-block;
  min-height: 120px;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  width: calc(100% / 3);
  html.ie8 &,
  html.ie9 & {
    width: 33.33%; }
  .block-title {
    position: relative;
    z-index: 2;
    h2 {
      @extend .icon-arrow-right;
      @include rem(font-size, 24px);
      color: #FFFFFF;
      font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
      line-height: 120px;
      margin: 0 50px;
      padding: 0;
      &::before {
        @include rem(font-size, 32px);
        float: right; }
      .text {
        display: inline-block;
        line-height: 1;
        padding: 0 25px 0 0;
        vertical-align: middle;
        width: calc(100% - 25px); } } }
  .block-image {
    @extend .clearfix;
    opacity: 0.1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 1;
    img {
      margin: 0;
      max-width: none;
      vertical-align: top;
      width: 100%; } }
  .block-footer {
    .block-more-link {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 3;
      @include hide-text;
      a {
        display: block;
        height: 100%; } } } }

.block-footer-top-mag {
  .block-title {
    h2 {
      .text {
        display: none; } } }
  .block-image {
    opacity: 1; } }

.block-footer-main-menu {
  .block-content {
    .menu {
      font-size: 0;
      margin: 50px 0 0 0;
      padding: 0;
      li {
        @include rem(font-size, 18px);
        @include rem(line-height, 20px);
        display: inline-block;
        font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
        margin: 0 60px 0 0;
        padding: 0;
        text-transform: uppercase;
        vertical-align: top;
        &.last {
          margin-right: 0; }
        @each $colorname, $color in $colors {
          &.menu-#{$colorname} {
            ul {
              li {
                &.has-subsection {
                  > a {
                    color: $color; } } } } } }
        &::before {
          display: none; }
        br {
          content: "";
          display: inline-block;
          width: 5px; }
        .text {
          display: inline-block;
          line-height: 1;
          vertical-align: middle; }
        ul {
          margin: 30px 0;
          padding: 0;
          li {
            @include rem(font-size, 14px);
            @include rem(line-height, 24px);
            display: block;
            font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
            margin: 0;
            padding: 0;
            text-transform: none;
            &.has-subsection {
              @include rem(font-size, 18px);
              @include rem(line-height, 24px);
              font-family: "Market Web", Arial, Verdana, sans-serif;
              margin: 30px 0 15px 0; } }
          ul {
            margin: 15px 0;
            padding: 0;
            li {
              margin: 0;
              padding: 0; } } } }
      a {
        color: #FFFFFF;
        text-decoration: none; } } } }

.block-footer-social-menu {
  border-top: 2px solid rgba(map-get($colors, search), 0.5);
  min-height: 165px;
  .block-inner {
    @extend .clearfix; }
  .block-title {
    float: left;
    h2 {
      @include rem(font-size, 21px);
      @include rem(line-height, 21px);
      color: #FFFFFF;
      font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
      margin: 30px 0;
      padding: 0;
      text-transform: uppercase; } }
  .block-content {
    float: right;
    .menu {
      font-size: 0;
      margin: 30px 0;
      padding: 0;
      text-align: right;
      li {
        @include rem(font-size, 25px);
        @include rem(line-height, 25px);
        display: inline-block;
        margin: 0 0 0 20px;
        padding: 0;
        vertical-align: middle;
        &::before {
          display: none; }
        .text {
          display: none; } }
      a {
        color: #FFFFFF;
        display: block;
        height: 25px;
        padding: 0 10px;
        text-decoration: none; } } } }

.block-footer-newsletter {
  border-top: 2px solid rgba(map-get($colors, search), 0.5);
  margin-bottom: 30px;
  min-height: 165px;
  padding-bottom: 30px;
  .block-title {
    h2 {
      @include rem(font-size, 21px);
      @include rem(line-height, 21px);
      color: #FFFFFF;
      font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
      margin: 30px 0 20px 0;
      padding: 0;
      text-transform: uppercase; } }
  .block-content {
    font-size: 0;
    .form-item {
      margin: 5px 5px 5px 0; }
    .mailchimp-newsletter-mergefields {
      @include rem(font-size, 18px);
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      width: calc(100% - 85px);
      html.ie8 &,
      html.ie9 & {
        width: 280px; } }
    .form-text {
      @include rem(font-size, 16px);
      border: 0;
      font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
      outline: none;
      padding: 8px;
      width: 100%;
      &::-webkit-input-placeholder {
        color: map-get($colors, primary); }
      &::-moz-placeholder {
        color: map-get($colors, primary); }
      &:-ms-input-placeholder {
        color: map-get($colors, primary); }
      &:-moz-placeholder {
        color: map-get($colors, primary); } }
    .form-actions {
      @include rem(font-size, 18px);
      display: inline-block;
      margin: 0;
      padding: 0;
      vertical-align: middle; }
    .form-submit {
      @include rem(font-size, 16px);
      background-color: map-get($colors, city);
      border-radius: 4px;
      font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
      height: auto;
      line-height: 1;
      margin: 0;
      min-width: 80px;
      padding: 0 8px; } } }

.block-footer-secondary-menu {
  .block-content {
    .menu {
      font-size: 0;
      margin: 0;
      padding: 0;
      li {
        @include rem(font-size, 16px);
        display: inline-block;
        line-height: $footer_bottom_height;
        margin: 0 30px 0 0;
        padding: 0;
        &::before {
          display: none; }
        .text {
          display: inline-block;
          line-height: 1;
          vertical-align: middle; } }
      a {
        color: #FFFFFF;
        padding: 0 20px 0 0;
        text-decoration: none;
        &::before {
          content: "|";
          display: inline-block;
          padding: 0 10px 0 0; } } } } }
