// ==================================================
// Forms
// ==================================================

form {
  @extend .clearfix; }

legend {
  @extend .h3;
  margin-bottom: 0; }

.fieldset-description {
  @include rem(font-size, 14px);
  @include rem(line-height, 14px);
  color: map-get($colors, search);
  margin: 15px 0; }

.form-required {
  color: map-get($colors, city); }

.form-item {
  @extend .clearfix;
  margin: 25px 0;
  label {
    @include rem(font-size, 18px);
    @include rem(line-height, 27px);
    color: map-get($colors, primary);
    display: inline-block;
    font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
    margin: 0 15px 0 0;
    text-align: right;
    vertical-align: middle;
    width: 180px;
    &.option {
      @include rem(font-size, 16px);
      display: inline;
      margin-right: 0;
      text-align: left;
      width: auto; } }
  .description {
    @include rem(font-size, 14px);
    @include rem(line-height, 14px);
    color: map-get($colors, search);
    margin: 5px 0 5px 200px; }
  .field-prefix {
    @include rem(font-size, 18px);
    @include rem(line-height, 27px);
    color: map-get($colors, primary); } }

.form-radios {
  display: inline-block;
  vertical-align: top;
  .form-item {
    display: block;
    margin: 0 0 5px 0;
    vertical-align: middle; } }

.form-text {
  @include rem(font-size, 16px);
  appearance: none;
  border: 1px solid rgba(#BBBDBE, 0.5);
  border-radius: 4px;
  color: map-get($colors, primary);
  display: inline-block;
  font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
  opacity: 1;
  padding: 8px;
  vertical-align: middle;
  width: 300px;
  &[disabled="disabled"] {
    color: map-get($colors, primary) !important; } }

.form-textarea-wrapper {
  display: inline-block; }

.form-textarea {
  @include rem(font-size, 16px);
  appearance: none;
  border: 1px solid rgba(#BBBDBE, 0.5);
  border-radius: 4px;
  color: map-get($colors, primary);
  font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
  min-height: 200px;
  padding: 8px;
  resize: vertical;
  vertical-align: middle;
  width: 100%; }

.form-select {
  @include rem(font-size, 16px);
  border: 1px solid rgba(#BBBDBE, 0.5);
  font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
  padding: 8px;
  width: 360px;
  &.day,
  &.month,
  &.year,
  &.hour,
  &.minute,
  &.short {
    width: auto; }
  &.minute {
    margin-left: 5px; } }

.form-actions {
  margin: 30px 0;
  padding-left: 200px;
  text-align: left;
  .form-submit {
    margin: 0; } }

.form-submit {
  @include rem(font-size, 16px);
  @include rem(line-height, 50px);
  appearance: none;
  background-color: map-get($colors, city);
  background-image: none;
  border: 0;
  border-radius: 4px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
  margin: 0 0 30px 200px;
  min-height: 50px;
  padding: 0 40px;
  text-transform: uppercase;
  transition: all 0.2s;
  html.no-touchevents &:hover {
    background-color: darken(map-get($colors, city), 15%);
    color: #FFFFFF; } }

.webform-container-inline {
  div.form-item,
  .webform-calendar {
    display: inline-block;
    margin: 0;
    vertical-align: middle; } }

.webform-component-markup {
  margin: 0; }

.captcha {
  margin: 30px 0 30px 200px;
  iframe {
    margin: 0; } }
