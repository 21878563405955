// ==================================================
// Buttons
// ==================================================

.btn {
  @include rem(font-size, 24px);
  @include rem(line-height, 24px);
  appearance: none;
  background-color: map-get($colors, primary);
  border: 0;
  border-radius: 4px;
  color: #FFFFFF;
  display: inline-block;
  font-family: "Titillium Web Regular", Arial, Verdana, sans-serif;
  padding: 17px 30px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;
  html.no-touchevents &:hover {
    background-color: darken(map-get($colors, primary), 15%);
    color: #FFFFFF; } }

.btn-small {
  @include rem(font-size, 18px);
  @include rem(line-height, 18px);
  font-family: "Titillium Web Bold", Arial, Verdana, sans-serif;
  min-height: auto;
  padding: 15px 25px; }

.btn-large {
  @include rem(font-size, 32px);
  @include rem(line-height, 32px);
  padding: 27px 40px; }

@each $colorname, $color in $colors {
  .btn-#{$colorname} {
    background-color: $color;
    color: #FFFFFF;
    html.no-touchevents &:hover {
      background-color: darken($color, 15%);
      color: #FFFFFF; } } }
