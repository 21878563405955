// ==================================================
// Root
// ==================================================

html {
  &.svg {
    img.svg {
      display: inline-block;
      visibility: visible; }
    img.no-svg {
      display: none;
      visibility: hidden; } }
  &.no-svg {
    img.svg {
      display: none;
      visibility: hidden; }
    img.no-svg {
      display: inline-block;
      visibility: visible; } } }

body {
  background-color: #FFFFFF;
  overflow-x: hidden;
  padding-top: calc(#{$header_height} + 4px);
  html.csstransforms3d &.snapjs-left,
  html.csstransforms3d &.snapjs-right {
    overflow-y: hidden;
    .page {
      left: 0;
      position: fixed;
      top: 0; }
    .header {
      position: relative; } }
  html.csstransforms3d &.snapjs-left {
    .header-toggle-left-panel {
      color: map-get($colors, search); } }
  html.csstransforms3d &.snapjs-right {
    .header-toggle-right-panel {
      color: map-get($colors, search); } } }

iframe {
  display: block;
  margin: 60px auto; }

// ==================================================
// Off canvas
// ==================================================

.panels {
  .panel {
    color: #FFFFFF;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    transition: all 0.2s;
    width: 260px;
    z-index: 1;
    &.panel-open {
      transform: translate3d(0, 0, 0); }
    body.admin-menu & {
      top: 29px; }
    body.admin-menu-with-shortcuts & {
      top: 64px; }
    a {
      color: #FFFFFF; } }
  .panel-left {
    background-color: #86A3B6;
    left: 0;
    overflow-y: auto;
    transform: translate3d(-260px, 0, 0); }
  .panel-right {
    background-color: map-get($colors, primary);
    right: 0;
    overflow: hidden;
    transform: translate3d(260px, 0, 0); } }

// ==================================================
// Page
// ==================================================

.page {
  background-color: #FFFFFF;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 2; }

// ==================================================
// Header
// ==================================================

.header {
  background-color: map-get($colors, primary);
  border-bottom: 4px solid map-get($colors, search);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  body.admin-menu & {
    top: 29px; }
  body.admin-menu-with-shortcuts & {
    top: 64px; }
  .container {
    min-height: calc(#{$header_height} + 4px); } }

.header-toggle-left-panel,
.header-toggle-right-panel {
  color: #FFFFFF;
  cursor: pointer;
  display: none;
  .icon {
    @include rem(font-size, 24px);
    @include rem(line-height, 24px);
    display: inline-block;
    margin-top: 19px;
    transition: all 0.2s;
    vertical-align: middle; }
  .text {
    display: none; } }

.header-toggle-left-panel {
  text-align: left; }

.header-logo {
  a {
    display: block;
    line-height: $header_height; }
  img {
    display: inline-block;
    margin: 0;
    max-width: 100%;
    vertical-align: middle; } }

.header-site-name,
.header-site-slogan {
  display: none; }

// ==================================================
// Highlighted
// ==================================================

.highlighted {
  position: relative;
  z-index: 1; }

// ==================================================
// Content
// ==================================================

.content {
  html.front & {
    background: transparent url("../img/home-content.jpg") no-repeat 0 0;
    background-size: contain; }
  overflow-x: hidden;
  position: relative;
  z-index: 1; }

.content-bottom {
  @extend .clearfix;
  position: relative;
  z-index: 1; }

// ==================================================
// Footer
// ==================================================

.footer {
  background-color: map-get($colors, primary);
  position: relative;
  z-index: 1; }

.footer-top {
  @extend .clearfix;
  font-size: 0;
  position: relative;
  z-index: 1; }

.footer-bottom {
  background-color: #5B6B77;
  position: relative;
  z-index: 1;
  .container {
    min-height: $footer_bottom_height; } }

.footer-logo {
  line-height: $footer_bottom_height;
  text-align: right;
  img {
    display: inline-block;
    margin: 0;
    max-width: 100%;
    vertical-align: middle; } }
